const initialValues = {
  error: null,
  reportTableData: [],
  selectedRecos: [],
  selected: [],
  reportTitle: "",
  reportData: {},
};

export default initialValues;
