import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import state from "./state";
import axiosApi from "helper/axiosApi";

export const getAllCombineReportList = createAsyncThunk(
  "getAllCombineReportList",
  async (_, { rejectWithValue }) => {
    try {
      const res = await axiosApi.get(
        `/reconreport/api/v1/combinesummaryreport`
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteCombineReport = createAsyncThunk(
  "deleteCombineReport",
  async ({ id }, { rejectWithValue }) => {
    try {
      const res = await axiosApi.delete(
        `/reconreport/api/v1/combinesummaryreport/${id}`
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const createCombineReportList = createAsyncThunk(
  "createCombineReportList",
  async ({ data }, { rejectWithValue }) => {
    try {
      const res = await axiosApi.post(
        `/reconreport/api/v1/combinesummaryreport`,
        data
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const getReportInfo = createAsyncThunk(
  "getReportInfo",
  async (id, { rejectWithValue }) => {
    try {
      const res = await axiosApi.get(
        `/reconreport/api/v1/combinesummaryreport/${id}`
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateCombineReportList = createAsyncThunk(
  "updateCombineReportList",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const res = await axiosApi.put(
        `/reconreport/api/v1/combinesummaryreport/${id}`,
        data
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

const combineReportSlice = createSlice({
  name: "combineReport",
  initialState: state,
  extraReducers: {
    [getAllCombineReportList.pending]: (state, action) => {
      state.status = "loading";
      state.type = "GET_ALL_COMBINE_REPORT";
    },
    [getAllCombineReportList.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "GET_ALL_COMBINE_REPORT";
      state.reportTableData = action.payload;
    },
    [getAllCombineReportList.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "GET_ALL_COMBINE_REPORT";
      state.error = action.payload;
    },
    [deleteCombineReport.pending]: (state, action) => {
      state.status = "loading";
      state.type = "DELETE_COMBINE_REPORT";
    },
    [deleteCombineReport.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "DELETE_COMBINE_REPORT";
    },
    [deleteCombineReport.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "DELETE_COMBINE_REPORT";
      state.error = action.payload;
    },
    [createCombineReportList.pending]: (state, action) => {
      state.status = "loading";
      state.type = "CREATE_COMBINE_REPORT";
    },
    [createCombineReportList.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "CREATE_COMBINE_REPORT";
    },
    [createCombineReportList.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "CREATE_COMBINE_REPORT";
      state.error = action.payload;
    },
    [getReportInfo.pending]: (state, action) => {
      state.status = "loading";
      state.type = "GET_REPORT_INFO";
    },
    [getReportInfo.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "GET_REPORT_INFO";
    },
    [getReportInfo.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "GET_REPORT_INFO";
      state.error = action.payload;
    },
    [updateCombineReportList.pending]: (state, action) => {
      state.status = "loading";
      state.type = "UPDATE_COMBINE_REPORT";
    },
    [updateCombineReportList.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "UPDATE_COMBINE_REPORT";
    },
    [updateCombineReportList.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "UPDATE_COMBINE_REPORT";
      state.error = action.payload;
    },
  },
  reducers: {
    setSelectedRecos(state, action) {
      state.selectedRecos = action.payload;
    },
    setSelected(state, action) {
      state.selected = action.payload;
    },
    setReportTitle(state, action) {
      state.reportTitle = action.payload;
    },
    setReportData(state, action) {
      state.reportData = action.payload;
    },
  },
});

export const { setSelectedRecos, setSelected, setReportTitle, setReportData } =
  combineReportSlice.actions;

const { reducer } = combineReportSlice;

export default reducer;
