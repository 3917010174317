import {
  AutomationIcon,
  DirectRecoIcon,
  ManagePartyIcon,
  ReconSettingIcon,
  RequestStatementIcon,
  SFTPIcon,
  ScheduleIcon,
  SettingsIcon,
  TrackRecoIcon,
} from "components/AllSvg";
import { ReportsIcon } from "components/AllSvg2";
import {
  AUTOMATION,
  COMPANY_RECO_SETTINGS,
  COMPANY_STATEMENT,
  DIRECT_RECO_TABLE,
  PARTY_MASTER_DATA,
  RECO_SETTINGS,
  REPORTS,
  REQUEST_STATEMENT,
  SCHEDULER,
  SFTP,
} from "utils/constant";

const pagesSection = [
  {
    href: `/${PARTY_MASTER_DATA}`,
    icon: ManagePartyIcon,
    title: "Manage Party",
  },
  {
    href: `/${COMPANY_STATEMENT}`,
    icon: RequestStatementIcon,
    title: "Request Statement",
  },
  {
    href: `/${DIRECT_RECO_TABLE}`,
    icon: DirectRecoIcon,
    title: "Direct Reconciliation",
  },
  {
    href: `/${REQUEST_STATEMENT}`,
    icon: TrackRecoIcon,
    title: "Track Reconciliation",
  },
  // {
  //   href: `/${REPORTS}`,
  //   icon: ReportsIcon,
  //   title: "Reports",
  // },
  {
    href: `/${AUTOMATION}`,
    icon: AutomationIcon,
    title: "Automation",
    children: [
      {
        href: `/${AUTOMATION}/${SCHEDULER}`,
        title: "Schedule Request",
        icon: ScheduleIcon,
      },
    ],
  },
  {
    href: `/${RECO_SETTINGS}`,
    icon: SettingsIcon,
    title: "Settings",
    children: [
      {
        href: `/${RECO_SETTINGS}/${COMPANY_RECO_SETTINGS}`,
        title: "Reconciliation Settings",
        icon: ReconSettingIcon,
      },
    ],
  },
  {
    href: `/${SFTP}`,
    icon: SFTPIcon,
    title: "Statement Transformation",
  },
  // {
  //   href: "/actionrecosummary",
  //   icon: TrackRecoIcon,
  //   title: "Reco Summary",
  // },
];

const navItems = [
  {
    pages: pagesSection,
  },
];

export default navItems;
