import React, { useState } from "react";
import { CircularProgress } from "@mui/material";

const loaderStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
  width: "100%",
  position: "absolute",
  top: "0",
  left: "0",
  zIndex: "9999",
  backgroundColor: "#ffffff",
};

const parentStyle = {
  position: "relative",
};

export const IsLoadingHOC = (WrappedComponent) => {
  function HOC(props) {
    const [isLoading, setLoading] = useState(true);
    const setLoadingState = (isComponentLoading) => {
      setLoading(isComponentLoading);
    };

    return (
      <>
        <div style={parentStyle}>
          {isLoading && (
            <div style={loaderStyle}>
              <CircularProgress />
            </div>
          )}
          <WrappedComponent {...props} setLoading={setLoadingState} />
        </div>
      </>
    );
  }
  return HOC;
};
export default IsLoadingHOC;

export const Loader = () => {
  return (
    <div style={loaderStyle}>
      <CircularProgress />
    </div>
  );
};
