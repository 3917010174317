import axios from "axios";
import { IS_MULTI_TAB_SUPPORT } from "utils/constant";

const baseURL = process.env.REACT_APP_BASE_URL;

const axiosPartyApi = axios.create({
  baseURL: baseURL,
  headers: {
    common: {
      Authorization: IS_MULTI_TAB_SUPPORT ? sessionStorage.getItem("partyToken") : localStorage.getItem("partyToken"),
    },
  },
});

export default axiosPartyApi;
