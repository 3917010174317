import React, { forwardRef, useState } from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { rgba } from "polished";
import {
  Chip,
  Collapse,
  Fade,
  ListItemButton,
  ListItemText,
  Paper,
  Popper,
  Typography,
  Tooltip,
  tooltipClasses,
} from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import {
  setActiveTab,
  setConfigData,
  setExistingRecoId,
  setOpenGood,
  setSelected,
} from "store/newparty/slice";
import { styled as customStyle } from "@mui/system";

const CustomRouterLink = forwardRef((props, ref) => (
  <div ref={ref}>
    <NavLink {...props} />
  </div>
));

const Item = styled(ListItemButton)`
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};
  svg {
    color: #fff;
    font-size: 20px;
    width: 20px;
    height: 20px;
    opacity: 1;
  }
  &:hover {
    background: rgba(0, 0, 0, 0.2);
    color: ${(props) => props.theme.sidebar.color};
    cursor: pointer;
  }
  &.${(props) => props.activeclassname} {
    background-color: #005724;
    border-right: 3px solid #ff9a01;
    span {
      color: ${(props) => props.theme.sidebar.color};
    }
  }
`;

const Title = styled(ListItemText)`
  margin: 0;
  span {
    color: ${(props) =>
      rgba(
        props.theme.sidebar.color,
        props.depth && props.depth > 0 ? 0.7 : 1
      )};
    font-size: ${(props) => props.theme.typography.body1.fontSize}px;
    font-family: "SFProdisplay";
    padding: 0 ${(props) => props.theme.spacing(4)};
    display: ${(props) => (props.isMiniSidebar ? "none" : "block")};
  }
`;

const Badge = styled(Chip)`
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
  height: 20px;
  position: absolute;
  right: 26px;
  top: 12px;
  background: ${(props) => props.theme.sidebar.badge.background};
  z-index: 1;
  span.MuiChip-label,
  span.MuiChip-label:hover {
    font-size: 11px;
    cursor: pointer;
    color: ${(props) => props.theme.sidebar.badge.color};
  }
`;

const ExpandLessIcon = styled(ExpandLess)`
  color: ${(props) => rgba(props.theme.sidebar.color, 0.5)};
`;

const ExpandMoreIcon = styled(ExpandMore)`
  color: ${(props) => rgba(props.theme.sidebar.color, 0.5)};
`;

const CustomTooltip = customStyle(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#000000",
    color: "white",
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "#000000",
  },
}));

const SidebarNavListItem = (props) => {
  const {
    title,
    href,
    depth = 0,
    children,
    icon: Icon,
    badge,
    open: openProp = false,
  } = props;

  const [open, setOpen] = useState(openProp);
  const [anchorEl, setAnchorEl] = useState(null);
  const [popperOpen, setPopperOpen] = useState(false);

  const dispatch = useDispatch();
  const { isMiniSidebar } = useSelector((state) => state.trackreconciliation);

  const onMouseEnter = (event) => {
    if (event.currentTarget) {
      setAnchorEl(event.currentTarget);
      setPopperOpen(true);
    }
  };

  const onMouseLeave = (event) => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      setPopperOpen(false);
    }
  };

  if (children) {
    return (
      <>
        <Item
          depth={depth}
          onMouseEnter={isMiniSidebar ? onMouseEnter : null}
          onMouseLeave={isMiniSidebar ? onMouseLeave : null}
          onClick={() => {
            if (!isMiniSidebar) {
              setOpen((state) => !state);
            }
            setPopperOpen(false);
          }}
          activeclassname="active"
        >
          <Typography>{Icon && <Icon />}</Typography>
          <Title depth={depth} isMiniSidebar={isMiniSidebar}>
            {title}
            {badge && <Badge label={badge} />}
          </Title>
          {!isMiniSidebar && (open ? <ExpandLessIcon /> : <ExpandMoreIcon />)}
        </Item>

        {!isMiniSidebar ? (
          <Collapse in={open}>{children}</Collapse>
        ) : (
          <Popper
            sx={{
              zIndex: 9999,
              backgroundColor: "#233044",
              color: "#FFF",
              width: "auto",
            }}
            open={popperOpen}
            anchorEl={anchorEl}
            placement={"right-start"}
            transition
            onMouseEnter={() => setPopperOpen(true)}
            onMouseLeave={onMouseLeave}
          >
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={200}>
                <Paper sx={{ backgroundColor: "#233044" }}>
                  <Typography sx={{ p: 1, color: "#FFF" }}>
                    {children}
                  </Typography>
                </Paper>
              </Fade>
            )}
          </Popper>
        )}
      </>
    );
  }

  const setReco = () => {
    if (title === "Request Statement") {
      dispatch(setExistingRecoId(""));
      dispatch(setOpenGood(false));
    }
  };

  return (
    <>
      <CustomTooltip
        title={isMiniSidebar && !depth ? title : ""}
        placement="right"
        arrow
      >
        <Item
          depth={depth}
          component={CustomRouterLink}
          to={href}
          activeclassname="active"
          onClick={() => {
            dispatch(setActiveTab(1));
            dispatch(setSelected([]));
            dispatch(setConfigData({}));
            setPopperOpen(false);
          }}
          onMouseEnter={() => setPopperOpen(false)}
        >
          <Typography>
            {Icon && <Icon />} {isMiniSidebar && depth ? title : ""}
          </Typography>
          <Title depth={depth} isMiniSidebar={isMiniSidebar} onClick={setReco}>
            {title}
            {badge && <Badge label={badge} />}
          </Title>
        </Item>
      </CustomTooltip>
    </>
  );
};

export default SidebarNavListItem;
