import React from "react";
import { IS_MULTI_TAB_SUPPORT } from "utils/constant";

const initialState = {
  theme: "DEFAULT",
  setTheme: (theme) => { },
};
const ThemeContext = React.createContext(initialState);

function ThemeProvider({ children }) {
  const initialState = () => {
    const storedTheme = IS_MULTI_TAB_SUPPORT ? sessionStorage.getItem("theme") : localStorage.getItem("theme");

    return storedTheme ? JSON.parse(storedTheme) : "DEFAULT";
  };

  const [theme, _setTheme] = React.useState(initialState());

  const setTheme = (theme) => {
    IS_MULTI_TAB_SUPPORT ? sessionStorage.setItem("theme", JSON.stringify(theme)) : localStorage.setItem("theme", JSON.stringify(theme));
    _setTheme(theme);
  };

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      {children}
    </ThemeContext.Provider>
  );
}

export { ThemeProvider, ThemeContext };
