import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosApi from "../../helper/axiosApi";
import state from "./state";

// select party dropdown
export const getSelectPartyType = createAsyncThunk(
  "getSelectPartyType",
  async (_, { rejectWithValue }) => {
    try {
      const res = await axiosApi.get(
        `/partymaster/api/v1/party/summary/by-company`
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// to get display settings for right side section
export const getDisplaySettings = createAsyncThunk(
  "getDisplaySettings",
  async (_, { rejectWithValue }) => {
    try {
      const res = await axiosApi.get(
        `/reconinit/api/v1/recorecords/directreco/settings`
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// send all data on create click
export const selectAllDirectData = createAsyncThunk(
  "selectAllDirectData",
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosApi.post(
        `/reconinit/api/v1/recorecords/directreco/configure`,
        data
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// map company statement file
export const mapCompanyStatement = createAsyncThunk(
  "mapCompanyStatement",
  async ({ recoId, data }, { rejectWithValue }) => {
    try {
      const res = await axiosApi.put(
        // `reconinit/api/v1/recorecords/${recoId}/company/map`,
        `/reconinit/api/v1/recorecords/${recoId}/company/ledger/upload`,
        data
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// map party statement file
export const mapPartyStatement = createAsyncThunk(
  "mapPartyStatement",
  async ({ recoId, data }, { rejectWithValue }) => {
    try {
      const res = await axiosApi.put(
        `reconinit/api/v1/recorecords/${recoId}/party/map`,
        data
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// validate party statement file
export const validatePartyStatement = createAsyncThunk(
  "validatePartyStatement",
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosApi.put(
        `reconinit/api/v1/recorecords/party/validate`,
        data
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// validate company statement file
export const validateCompanyStatement = createAsyncThunk(
  "validateCompanyStatement",
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosApi.put(
        `reconinit/api/v1/recorecords/company/validate`,
        data
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// remove company statement file
export const removeCompanyStatement = createAsyncThunk(
  "removeCompanyStatement",
  async ({ recoId, statementId, partyId }, { rejectWithValue }) => {
    try {
      const res = await axiosApi.put(
        `reconinit/api/v1/recorecords/${recoId}/company/remove?statementId=${statementId}&partyId=${partyId}`
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// remove party statement file
export const removePartyStatement = createAsyncThunk(
  "removePartyStatement",
  async ({ recoId, statementId, partyId }, { rejectWithValue }) => {
    try {
      const res = await axiosApi.put(
        `reconinit/api/v1/recorecords/${recoId}/party/remove?statementId=${statementId}&partyId=${partyId}`
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// get summary details
export const getSummary = createAsyncThunk(
  "getSummary",
  async ({ recoId, statementId, partyId }, { rejectWithValue }) => {
    try {
      const res = await axiosApi.get(
        // `reconinit/api/v1/statement/mappedsummary/by-statement?recoId=${recoId}&statementId=${statementId}`
        `/reconinit/api/v1/statement/uploadsummary/by-statement?recoId=${recoId}&statementId=${statementId}&partyId=${partyId}`
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// get other summary details
export const getOtherSummary = createAsyncThunk(
  "getOtherSummary",
  async ({ recoId, statementId }, { rejectWithValue }) => {
    try {
      const res = await axiosApi.get(
        `reconinit/api/v1/statement/mappedsummary/by-statement?recoId=${recoId}&statementId=${statementId}`
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// load party statement
export const loadPartyStatement = createAsyncThunk(
  "loadPartyStatement",
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosApi.post(
        `reconinit/api/v1/recorecords/party/load-statement`,
        data
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// load company statement
export const loadCompanyStatement = createAsyncThunk(
  "loadCompanyStatement",
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosApi.post(
        `reconinit/api/v1/recorecords/company/load-statement`,
        data
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// map company- other Data statement
export const mapCompanyOtherStatement = createAsyncThunk(
  "mapCompanyOtherStatement",
  async ({ data, recoId }, { rejectWithValue }) => {
    try {
      const res = await axiosApi.put(
        // `/reconinit/api/v1/recorecords/${recoId}/company/map/otherdata`,
        `/reconinit/api/v1/recorecords/${recoId}/company/otherdata/upload`,
        data
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// map party other statement
export const mapPartyOtherStatement = createAsyncThunk(
  "mapPartyOtherStatement",
  async ({ data, recoId }, { rejectWithValue }) => {
    try {
      const res = await axiosApi.put(
        `/reconinit/api/v1/recorecords/${recoId}/party/map/otherdata`,
        data
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// validate company other statement
export const validateCompanyOther = createAsyncThunk(
  "validateCompanyOther",
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosApi.put(
        `/reconinit/api/v1/recorecords/company/validate/otherdata`,
        data
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// validate party other statement
export const validatePartyOther = createAsyncThunk(
  "validatePartyOther",
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosApi.put(
        `/reconinit/api/v1/recorecords/party/validate/otherdata`,
        data
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// Initiate reco on start reconciliation
export const initiateReco = createAsyncThunk(
  "initiateReco",
  async ({ recoId, partyId }, { rejectWithValue }) => {
    try {
      const res = await axiosApi.post(
        `/reconinit/api/v1/recorecords/initiate`,
        { recoId, partyId, version: 1 }
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// Get reco status
export const getRecoStatus = createAsyncThunk(
  "getRecoStatus",
  async ({ recoId, partyId }, { rejectWithValue }) => {
    try {
      const res = await axiosApi.put(`/reconinit/api/v1/recorecords/status`, {
        recoId,
        partyId,
      });
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

//download report
export const downloadReport = createAsyncThunk(
  "downloadReport",
  async ({ recoId, partyId }, { rejectWithValue }) => {
    try {
      axiosApi
        .post(
          `/reconreport/api/v1/recoadvancereport`,
          {
            recoId,
            partyId,
          },
          {
            responseType: "arraybuffer",
          }
        )
        .then((response) => {
          const url = window.URL.createObjectURL(
            new Blob([response.data], {
              type: "application/octet-stream",
              responseType: "arraybuffer",
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${partyId}_recoreport.xlsx`);
          // "reportName": "SwiftTrasport123-RecoReport.xlsx" append (partyId_recoreport)
          document.body.appendChild(link);
          link.click();
          link.remove();

          return response.data;
        });
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// Confirm Button click- To clear Reconciliation
export const confirmClearReco = createAsyncThunk(
  "confirmClearReco",
  async ({ recoId, partyId }, { rejectWithValue }) => {
    try {
      const res = await axiosApi.put(
        //  `/recon/api/v1/recorecords/reset-status`,
        `/reconinit/api/v1/recorecords/tracking/clear-reconciliation?recoId=${recoId}&partyId=${partyId}`
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

//to get data of direct reco table-1st landing screen
export const getDirectRecoTableData = createAsyncThunk(
  "getDirectRecoTableData",
  async ({ page, limit, sortBy, searchString }, { rejectWithValue }) => {
    try {
      const res = await axiosApi.get(
        `/reconinit/api/v1/recorecords/directreco/listing?pageNo=${page}&pageSize=${limit}${
          sortBy
            ? "&sortBy=" + sortBy.id + "&sortDirection=" + sortBy.direction
            : ""
        }&searchString=${searchString}`
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// to search:  Direct table data
// Search Option
export const getSearchDirectRecoTableData = createAsyncThunk(
  "getSearchDirectRecoTableData",
  async ({ searchString }, { rejectWithValue }) => {
    try {
      const res = await axiosApi.post(
        `/reconinit/api/v1/recorecords/directreco/search`,
        {
          searchString,
        }
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// To get data on view click-Pending,Complete
export const getViewClickRecoData = createAsyncThunk(
  "getViewClickRecoData",
  async ({ recoId, partyId }, { rejectWithValue }) => {
    try {
      const res = await axiosApi.get(
        `/reconinit/api/v1/recorecords/statements?recoId=${recoId}&partyId=${partyId}`
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

//New: to get summary on EyeIcon Click
// get summary details
export const getSummaryDataNew = createAsyncThunk(
  "getSummaryDataNew",
  async ({ recoId, statementId, partyId }, { rejectWithValue }) => {
    try {
      const res = await axiosApi.get(
        `/reconinit/api/v1/statement/uploadsummary/by-statement?recoId=${recoId}&statementId=${statementId}&partyId=${partyId}`
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// map company Ledger statement file
export const uploadCompanyLedgerStatement = createAsyncThunk(
  "uploadCompanyLedgerStatement",
  async ({ recoId, data }, { rejectWithValue }) => {
    try {
      const res = await axiosApi.put(
        `/reconinit/api/v1/recorecords/${recoId}/company/ledger/upload`,
        data
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// map company Open Item statement file
export const uploadCompanyOpenItemStatement = createAsyncThunk(
  "uploadCompanyOpenItemStatement",
  async ({ recoId, data }, { rejectWithValue }) => {
    try {
      const res = await axiosApi.put(
        `/reconinit/api/v1/recorecords/${recoId}/company/openitem/upload`,
        data
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// map company Other Data statement file
export const uploadCompanyOtherDataStatement = createAsyncThunk(
  "uploadCompanyOtherDataStatement",
  async ({ recoId, data }, { rejectWithValue }) => {
    try {
      const res = await axiosApi.put(
        `/reconinit/api/v1/recorecords/${recoId}/company/otherdata/upload`,
        data
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// map Party Ledger statement file
export const uploadPartyLedgerStatement = createAsyncThunk(
  "uploadPartyLedgerStatement",
  async ({ recoId, data }, { rejectWithValue }) => {
    try {
      const res = await axiosApi.put(
        `/reconinit/api/v1/recorecords/${recoId}/party/ledger/map`,
        data
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// map Party Open Item statement file
export const uploadPartyOpenItemStatement = createAsyncThunk(
  "uploadPartyOpenItemStatement",
  async ({ recoId, data }, { rejectWithValue }) => {
    try {
      const res = await axiosApi.put(
        `/reconinit/api/v1/recorecords/${recoId}/party/openitem/map`,
        data
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// remove Company Other Data statement file
export const removeCompanyOtherDataStatement = createAsyncThunk(
  "removeCompanyOtherDataStatement",
  async ({ recoId, statementId, partyId }, { rejectWithValue }) => {
    try {
      const res = await axiosApi.put(
        `reconinit/api/v1/recorecords/${recoId}/company/otherdata/remove?statementId=${statementId}&partyId=${partyId}`
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// To stop reconciliation
export const stopReconciliationProcess = createAsyncThunk(
  "stopReconciliationProcess",
  async ({ recoId, partyId }, { rejectWithValue }) => {
    try {
      const res = await axiosApi.put(
        `/reconorch/api/v1/processes/stop-process?recoId=${recoId}&partyId=${partyId}`
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

//download comapny doc report
export const downloadComapnyDocument = createAsyncThunk(
  "downloadComapnyDocument",
  async ({ recoId, partyId, doc, downloadName }, { rejectWithValue }) => {
    try {
      axiosApi
        .get(
          `/reconinit/api/v1/recorecords/company/download-statement?recoId=${recoId}&partyId=${partyId}&statementName=${doc}`,
          {
            responseType: "arraybuffer",
          }
        )
        .then((response) => {
          const url = window.URL.createObjectURL(
            new Blob([response.data], {
              type: "application/octet-stream",
              responseType: "arraybuffer",
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${downloadName ?? doc}`);
          // "reportName": "SwiftTrasport123-RecoReport.xlsx" append (partyId_recoreport)
          document.body.appendChild(link);
          link.click();
          link.remove();

          return response.data;
        });
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

//download party doc report
export const downloadPartyDocument = createAsyncThunk(
  "downloadComapnyDocument",
  async ({ recoId, partyId, doc }, { rejectWithValue }) => {
    try {
      axiosApi
        .get(
          `/reconinit/api/v1/recorecords/party/download-statement?recoId=${recoId}&partyId=${partyId}&statementName=${doc}`,
          {
            responseType: "arraybuffer",
          }
        )
        .then((response) => {
          const url = window.URL.createObjectURL(
            new Blob([response.data], {
              type: "application/octet-stream",
              responseType: "arraybuffer",
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${doc}`);
          // "reportName": "SwiftTrasport123-RecoReport.xlsx" append (partyId_recoreport)
          document.body.appendChild(link);
          link.click();
          link.remove();

          return response.data;
        });
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// get party names list select party popup
export const getPartyNames = createAsyncThunk(
  "getPartyNames",
  async ({ pageNo, searchString }, { rejectWithValue }) => {
    try {
      const res = await axiosApi.get(
        `/partymaster/api/v1/party/active/by-company?pageNo=${pageNo}&searchString=${searchString}`
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// get party names list-search string- select party popup
export const getPartyNameSearch = createAsyncThunk(
  "getPartyNameSearch",
  async ({ pageNo, searchString }, { rejectWithValue }) => {
    try {
      const res = await axiosApi.get(
        `/partymaster/api/v1/party/by-company?pageNo=${pageNo}&searchString=${searchString}`
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

//select records popup get Data and search data
export const getRecordsData = createAsyncThunk(
  "getRecordsData",
  async ({ partyId, pageNo, searchString }, { rejectWithValue }) => {
    try {
      const res = await axiosApi.get(
        `/recon/api/v1/recorecords/unmatchedrecos?partyId=${partyId}&pageNo=${pageNo}&searchString=${searchString}`
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// Direct Reconliation- create new reconciliation- send all data
export const createNewReco = createAsyncThunk(
  "createNewReco",
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosApi.post(
        `/reconinit/api/v2/recorecords/directreco/configure`,
        data
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

//edit setting screen -get previous data
export const getEditableRecoData = createAsyncThunk(
  "getEditableRecoData",
  async ({ recoId, partyId }, { rejectWithValue }) => {
    try {
      const res = await axiosApi.get(
        `/reconinit/api/v1/recorecords/recosettings?recoId=${recoId}&partyId=${partyId}`
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

//edit setting screen- Save Reco setting
export const saveRecoSettings = createAsyncThunk(
  "saveRecoSettings",
  async ({ data }, { rejectWithValue }) => {
    try {
      const res = await axiosApi.put(
        `/reconinit/api/v2/recorecords/update-settings`,
        // `/partymaster/api/v1/party/${partyId}/reco-settings`,
        data
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

//edit setting screen- save Settings And Rerun/Resetting Reco
export const saveSettingsAndRerunReco = createAsyncThunk(
  "saveSettingsAndRerunReco",
  async ({ data2 }, { rejectWithValue }) => {
    try {
      const res = await axiosApi.put(
        `/reconinit/api/v2/recorecords/update-settings-rerun`,
        data2
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

//New Reco Screen-Right Side settings pre filled -get previous data
export const getSelectedPartySettings = createAsyncThunk(
  "getSelectedPartySettings",
  async ({ partyId }, { rejectWithValue }) => {
    try {
      const res = await axiosApi.get(
        `/reconinit/api/v2/recorecords/directreco/settings?partyId=${partyId}`
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAllPartyUploadedNonExcel = createAsyncThunk(
  "getAllPartyUploadedNonExcel",
  async ({ partyId, recoId }, { rejectWithValue }) => {
    try {
      const res = await axiosApi.get(
        `reconinit/api/v1/recorecords/party/non-excelstatements?recoId=${recoId}&partyId=${partyId}`
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

//New Reco Screen-Right Side settings pre filled -get previous data
export const getAllPartyUploadedDocumentsList = createAsyncThunk(
  "getAllPartyUploadedDocumentsList",
  async ({ partyId, recoId }, { rejectWithValue }) => {
    try {
      const res = await axiosApi.get(
        `/partyconfmn/api/v2/recorecords/party/attachment?reconId=${recoId}&partyId=${partyId}`
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// upload ledger single party SFTP File
export const uploadSFTPFileSP = createAsyncThunk(
  "uploadSFTPFileSP",
  async (
    { recoIdForUploadLedger, formData, requestType },
    { rejectWithValue }
  ) => {
    try {
      const res = await axiosApi.put(
        `reconinit/api/v1/recorecords/${recoIdForUploadLedger}/company/${requestType}/sftp-upload`,
        formData
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// Fetch Data from airflow trigger-dag
export const triggerDagFromAirFlow = createAsyncThunk(
  "triggerDagFromAirFlow",
  async ({ data }, { rejectWithValue }) => {
    try {
      const res = await axiosApi.post(
        `stmt-transform/api/v1/transform/trigger-task`,
        data
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// Fetch Dag status from airFlow
export const fetchDagStatus = createAsyncThunk(
  "fetchDagStatus",
  async ({ pipeline, dag_run_id }, { rejectWithValue }) => {
    try {
      const res = await axiosApi.get(
        `stmt-transform/api/v1/transform/task-status/${pipeline}/${dag_run_id}`
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// Fetch transformed file details
export const fetchTransformedFileDetails = createAsyncThunk(
  "fetchTransformedFileDetails",
  async (
    { company_id, company_code, fwy_party_code, start_date, end_date },
    { rejectWithValue }
  ) => {
    try {
      const res = await axiosApi.get(
        `stmt-transform/api/v1/parties/get-metadata/?company_id=${company_id}&company_code=${company_code}&fwy_party_code=${fwy_party_code}&start_date=${start_date}&end_date=${end_date}`
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// upload ledger single party SFTP File
export const uploadSFTPGrirSP = createAsyncThunk(
  "uploadSFTPGrirSP",
  async ({ recoIdForUploadLedger, formData }, { rejectWithValue }) => {
    try {
      const res = await axiosApi.put(
        `reconinit/api/v1/recorecords/${recoIdForUploadLedger}/company/otherdata/sftp-upload`,
        formData
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// upload openGRIR single party SFTP File
export const uploadGrirSP = createAsyncThunk(
  "uploadGrirSP",
  async (
    { recoIdForUploadLedger, formData, requestType },
    { rejectWithValue }
  ) => {
    try {
      const res = await axiosApi.put(
        // `/reconinit/api/v1/recorecords/${recoIdForUploadLedger}/company-sftp/map`,
        `reconinit/api/v1/recorecords/${recoIdForUploadLedger}/company/${requestType}/sftp-upload`,
        formData
      );
      return res.data;
    } catch (error) {
      if (!error) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

const directReconciliationSlice = createSlice({
  name: "directReconciliationSlice",
  initialState: state,
  extraReducers: {
    [getSelectPartyType.pending]: (state, action) => {
      state.status = "loading";
      state.type = "GET_SELECTPARTY_TYPELIST";
    },
    [getSelectPartyType.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "GET_SELECTPARTY_TYPELIST";
      state.SelectPartyType = action.payload;
    },
    [getSelectPartyType.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "GET_SELECTPARTY_TYPELIST";
      state.error = action.payload;
    },
    [getDisplaySettings.pending]: (state, action) => {
      state.status = "loading";
      state.type = "GET_DISPLAYSETTING_DATA";
    },
    [getDisplaySettings.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "GET_DISPLAYSETTING_DATA";
      // state.SelectPartyType = action.payload;
      state.displaySettings = action.payload;
    },
    [getDisplaySettings.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "GET_DISPLAYSETTING_DATA";
      state.error = action.payload;
    },
    [selectAllDirectData.pending]: (state, action) => {
      state.status = "loading";
      state.type = "SELECT_ALL_DATA";
    },
    [selectAllDirectData.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "SELECT_ALL_DATA";
      state.configureData = action.payload;
      state.partyId = action.payload.party.partyId;
      state.recoId = action.payload.recoId;
    },
    [selectAllDirectData.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "SELECT_ALL_DATA";
      state.error = action.payload;
    },
    [mapCompanyStatement.pending]: (state, action) => {
      state.status = "loading";
      state.type = "MAP_COMPANY_STATEMENT";
    },
    [mapCompanyStatement.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "MAP_COMPANY_STATEMENT";
      state.mappingData = action.payload;
      state.openGood = action.payload?.hasErrors ? false : true;
      state.openVerify = false;
    },
    [mapCompanyStatement.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "MAP_COMPANY_STATEMENT";
      state.error = action.payload;
      state.openVerify = false;
    },
    [mapPartyStatement.pending]: (state, action) => {
      state.status = "loading";
      state.type = "MAP_PARTY_STATEMENT";
    },
    [mapPartyStatement.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "MAP_PARTY_STATEMENT";
      state.mappingData = action.payload;
      state.openGood = action.payload?.hasErrors ? false : true;
      state.openVerify = false;
    },
    [mapPartyStatement.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "MAP_PARTY_STATEMENT";
      state.error = action.payload;
    },
    [validatePartyStatement.pending]: (state, action) => {
      state.status = "loading";
      state.type = "VALIDATE_PARTY_STATEMENT";
    },
    [validatePartyStatement.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "VALIDATE_PARTY_STATEMENT";
      state.mappingData = action.payload;
      state.openGood = action.payload?.hasErrors ? false : true;
    },
    [validatePartyStatement.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "VALIDATE_PARTY_STATEMENT";
      state.error = action.payload;
    },
    [validateCompanyStatement.pending]: (state, action) => {
      state.status = "loading";
      state.type = "VALIDATE_COMPANY_STATEMENT";
    },
    [validateCompanyStatement.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "VALIDATE_COMPANY_STATEMENT";
      state.mappingData = action.payload;
      state.openGood = action.payload?.hasErrors ? false : true;
    },
    [validateCompanyStatement.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "VALIDATE_COMPANY_STATEMENT";
      state.error = action.payload;
    },
    [removeCompanyStatement.pending]: (state, action) => {
      state.status = "loading";
      state.type = "REMOVE_COMPANY_STATEMENT";
    },
    [removeCompanyStatement.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "REMOVE_COMPANY_STATEMENT";
      state.removedData = action.payload;
    },
    [removeCompanyStatement.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "REMOVE_COMPANY_STATEMENT";
      state.error = action.payload;
    },
    [removePartyStatement.pending]: (state, action) => {
      state.status = "loading";
      state.type = "REMOVE_PARTY_STATEMENT";
    },
    [removePartyStatement.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "REMOVE_PARTY_STATEMENT";
      state.removedData = action.payload;
    },
    [removePartyStatement.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "REMOVE_PARTY_STATEMENT";
      state.error = action.payload;
    },
    [getSummary.pending]: (state, action) => {
      state.status = "loading";
      state.type = "GET_SUMMARY";
    },
    [getSummary.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "GET_SUMMARY";
      state.summary = action.payload;
      state.mappingData = action.payload;
      state.openModal = true;
      state.openMapping = true;
      state.openGood = action.payload?.hasErrors ? false : true;
    },
    [getSummary.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "GET_SUMMARY";
      state.error = action.payload;
      state.openVerify = false;
    },
    [getOtherSummary.pending]: (state, action) => {
      state.status = "loading";
      state.type = "GET_OTHER_SUMMARY";
    },
    [getOtherSummary.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "GET_OTHER_SUMMARY";
      state.summary = action.payload;
      state.otherData = action.payload;
      state.openModal = true;
      state.openOtherMapping = true;
      state.openGood = action.payload?.hasErrors ? false : true;
    },
    [getOtherSummary.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "GET_OTHER_SUMMARY";
      state.error = action.payload;
      state.openVerify = false;
    },
    [loadPartyStatement.pending]: (state, action) => {
      state.status = "loading";
      state.type = "LOAD_PARTY_STATEMENT";
    },
    [loadPartyStatement.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "LOAD_PARTY_STATEMENT";
      state.loadData = action.payload;
      state.selectedFile = [];
      state.openMapping = false;
      state.openOtherMapping = false;
      state.openFormat = false;
      state.openModal = false;
    },
    [loadPartyStatement.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "LOAD_PARTY_STATEMENT";
      state.error = action.payload;
    },
    [loadCompanyStatement.pending]: (state, action) => {
      state.status = "loading";
      state.type = "LOAD_COMPANY_STATEMENT";
    },
    [loadCompanyStatement.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "LOAD_COMPANY_STATEMENT";
      state.loadData = action.payload;
      state.selectedFile = [];
      state.openMapping = false;
      state.openOtherMapping = false;
      state.openFormat = false;
      state.openModal = false;
    },
    [loadCompanyStatement.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "LOAD_COMPANY_STATEMENT";
      state.error = action.payload;
    },
    [mapCompanyOtherStatement.pending]: (state, action) => {
      state.status = "loading";
      state.type = "MAP_COMPANY_OTHER_STATEMENT";
    },
    [mapCompanyOtherStatement.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "MAP_COMPANY_OTHER_STATEMENT";
      state.otherData = action.payload;
      state.openGood = action.payload?.hasErrors ? false : true;
      state.openVerify = false;
    },
    [mapCompanyOtherStatement.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "MAP_COMPANY_OTHER_STATEMENT";
      state.error = action.payload;
      state.openVerify = false;
    },
    [mapPartyOtherStatement.pending]: (state, action) => {
      state.status = "loading";
      state.type = "MAP_PARTY_OTHER_STATEMENT";
    },
    [mapPartyOtherStatement.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "MAP_PARTY_OTHER_STATEMENT";
      state.otherData = action.payload;
      state.openGood = action.payload?.hasErrors ? false : true;
      state.openVerify = false;
    },
    [mapPartyOtherStatement.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "MAP_PARTY_OTHER_STATEMENT";
      state.error = action.payload;
    },
    [validateCompanyOther.pending]: (state, action) => {
      state.status = "loading";
      state.type = "VALIDATE_COMPANY_OTHER";
    },
    [validateCompanyOther.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "VALIDATE_COMPANY_OTHER";
      state.otherData = action.payload;
      state.openGood = action.payload?.hasErrors ? false : true;
    },
    [validateCompanyOther.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "VALIDATE_COMPANY_OTHER";
      state.error = action.payload;
      state.openVerify = false;
    },
    [validatePartyOther.pending]: (state, action) => {
      state.status = "loading";
      state.type = "VALIDATE_PARTY_OTHER";
    },
    [validatePartyOther.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "VALIDATE_PARTY_OTHER";
      state.otherData = action.payload;
      state.openGood = action.payload?.hasErrors ? false : true;
    },
    [validatePartyOther.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "VALIDATE_PARTY_OTHER";
      state.error = action.payload;
    },
    [initiateReco.pending]: (state, action) => {
      state.status = "loading";
      state.type = "INITIATE_RECO";
    },
    [initiateReco.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "INITIATE_RECO";
      state.isLoading = true;
    },
    [initiateReco.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "INITIATE_RECO";
      state.error = action.payload;
    },
    [getRecoStatus.pending]: (state, action) => {
      state.status = "loading";
      state.type = "GET_RECO_STATUS";
    },
    [getRecoStatus.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "GET_RECO_STATUS";
      state.recoStatus = action.payload;
    },
    [getRecoStatus.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "GET_RECO_STATUS";
      state.error = action.payload;
    },
    [downloadReport.pending]: (state, action) => {
      state.status = "loading";
      state.type = "DOWNLOAD_REPORT";
    },
    [downloadReport.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "DOWNLOAD_REPORT";
      state.report = action.payload;
    },
    [downloadReport.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "DOWNLOAD_REPORT";
      state.error = action.payload;
    },
    [confirmClearReco.pending]: (state, action) => {
      state.status = "loading";
      state.type = "CONFIRM_CLEAR";
    },
    [confirmClearReco.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "CONFIRM_CLEAR";
      state.confirmclear = action.payload;
      // state.configureData = action.payload;
      // state.partyId = action.payload.party.partyId;
      // state.recoId = action.payload.recoId;
    },
    [confirmClearReco.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "CONFIRM_CLEAR";
      state.error = action.payload;
    },
    [getDirectRecoTableData.pending]: (state, action) => {
      state.status = "loading";
      state.type = "GET_DATA_BY_DIRECTRECO";
    },
    [getDirectRecoTableData.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "GET_DATA_BY_DIRECTRECO";
      state.directTableData = action.payload;
    },
    [getDirectRecoTableData.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "GET_DATA_BY_DIRECTRECO";
      state.error = action.payload;
    },
    [getSearchDirectRecoTableData.pending]: (state, action) => {
      state.status = "loading";
      state.type = "SEARCH_DIRECTRECO";
    },
    [getSearchDirectRecoTableData.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "SEARCH_DIRECTRECO";
      state.directTableData = action.payload;
    },
    [getSearchDirectRecoTableData.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "SEARCH_DIRECTRECO";
      state.error = action.payload;
    },
    [getViewClickRecoData.pending]: (state, action) => {
      state.status = "loading";
      state.type = "GET_DATA_BY_VIEWCLICK";
    },
    [getViewClickRecoData.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "GET_DATA_BY_VIEWCLICK";
      state.configureData = action.payload;
      state.recoId = action.payload.recoId;
      state.partyId = action.payload.partyId;
      state.mappingStatus = action.payload.mappingSkipped;
      state.configureData.party = {};
      state.configureData.party.partyId = action.payload.partyId;
      state.recoStatus = action.payload.recoStatus;
      state.settingResponse = {};

      if (action.payload.companyLedgerStatementName !== null)
        state.companyLedgerArr = [
          {
            fileName: action.payload.companyLedgerStatementDisplayName,
            statementId: action.payload.companyLedgerStatementId,
            recoId: action.payload.recoId,
            partyId: action.payload.partyId,
          },
        ];
      if (action.payload.partyLedgerStatementName !== null)
        state.partyLedgerArr = [
          {
            fileName: action.payload.partyLedgerStatementDisplayName,
            statementId: action.payload.partyLedgerStatementId,
            recoId: action.payload.recoId,
            partyId: action.payload.partyId,
          },
        ];
      if (action.payload.companyOpenItemStatementName !== null)
        state.companyArr = [
          {
            fileName: action.payload.companyOpenItemStatementDisplayName,
            statementId: action.payload.companyOpenItemStatementId,
            recoId: action.payload.recoId,
            partyId: action.payload.partyId,
          },
        ];
      if (action.payload.partyOpenItemStatementName !== null) {
        state.partyArr = [
          {
            fileName: action.payload.partyOpenItemStatementDisplayName,
            statementId: action.payload.partyOpenItemStatementId,
            recoId: action.payload.recoId,
            partyId: action.payload.partyId,
          },
        ];
      }
      if (action.payload.companyOtherData1Name !== null) {
        state.otherCompany = [
          {
            statementName: action.payload.companyOtherData1DisplayName,
            statementId: action.payload.companyOtherData1Id,
            recoId: action.payload.recoId,
            partyId: action.payload.partyId,
            downloadStatement: action.payload.companyOtherData1Name,
          },
        ];
      }
      if (action.payload.companyOtherData2Name !== null)
        state.otherCompany = [
          ...state.otherCompany,
          {
            statementName: action.payload.companyOtherData2DisplayName,
            statementId: action.payload.companyOtherData2Id,
            recoId: action.payload.recoId,
            partyId: action.payload.partyId,
            downloadStatement: action.payload.companyOtherData2Name,
          },
        ];
      if (action.payload.companyOtherData3Name !== null)
        state.otherCompany = [
          ...state.otherCompany,
          {
            statementName: action.payload.companyOtherData3DisplayName,
            statementId: action.payload.companyOtherData3Id,
            recoId: action.payload.recoId,
            partyId: action.payload.partyId,
            downloadStatement: action.payload.companyOtherData3Name,
          },
        ];

      // if (action.payload.companyLedgerStatementName != null)
      // state.companyLedgerArr = [{
      //   fileName: action.payload.companyLedgerStatementName,
      //   statementId: action.payload.companyLedgerStatementId,
      //   recoId: action.payload.recoId,
      //   partyId: action.payload.partyId,
      // }];
      // if (action.payload.partyLedgerStatementName != null)
      // state.partyLedgerArr = [{
      //   fileName: action.payload.partyLedgerStatementName,
      //   statementId: action.payload.partyLedgerStatementId,
      //   recoId: action.payload.recoId,
      //   partyId: action.payload.partyId,
      // }];
      // if (action.payload.partyLedgerStatementName != null)
      // state.otherParty = [{
      //   fileName: action.payload.partyLedgerStatementName,
      //   statementId: action.payload.partyLedgerStatementId,
      //   recoId: action.payload.recoId,
      //   partyId: action.payload.partyId,
      // }];
    },
    [getViewClickRecoData.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "GET_DATA_BY_VIEWCLICK";
      state.error = action.payload;
    },

    //Company Ledger file upload
    [uploadCompanyLedgerStatement.pending]: (state, action) => {
      state.status = "loading";
      state.type = "MAP_COMPANY_LEDGER_STATEMENT";
    },
    [uploadCompanyLedgerStatement.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "MAP_COMPANY_LEDGER_STATEMENT";
      state.mappingData = action.payload;
      state.openGood = action.payload?.hasErrors ? false : true;
      state.openVerify = false;
    },
    [uploadCompanyLedgerStatement.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "MAP_COMPANY_LEDGER_STATEMENT";
      state.error = action.payload;
    },
    //Company Open Item file upload
    [uploadCompanyOpenItemStatement.pending]: (state, action) => {
      state.status = "loading";
      state.type = "MAP_COMPANY_OPENITEM_STATEMENT";
    },
    [uploadCompanyOpenItemStatement.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "MAP_COMPANY_OPENITEM_STATEMENT";
      state.mappingData = action.payload;
      state.openGood = action.payload?.hasErrors ? false : true;
      state.openVerify = false;
    },
    [uploadCompanyOpenItemStatement.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "MAP_COMPANY_OPENITEM_STATEMENT";
      state.error = action.payload;
    },
    //Company Other data file upload
    [uploadCompanyOtherDataStatement.pending]: (state, action) => {
      state.status = "loading";
      state.type = "MAP_COMPANY_OTHERDATA_STATEMENT";
    },
    [uploadCompanyOtherDataStatement.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "MAP_COMPANY_OTHERDATA_STATEMENT";
      state.otherData = action.payload;
      state.openGood = action.payload?.hasErrors ? false : true;
      state.openVerify = false;
    },
    [uploadCompanyOtherDataStatement.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "MAP_COMPANY_OTHERDATA_STATEMENT";
      state.error = action.payload;
    },
    //Map party Ledger Statement
    [uploadPartyLedgerStatement.pending]: (state, action) => {
      state.status = "loading";
      state.type = "MAP_PARTY_LEDGER_STATEMENT";
    },
    [uploadPartyLedgerStatement.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "MAP_PARTY_LEDGER_STATEMENT";
      state.mappingData = action.payload;
      state.openGood = action.payload?.hasErrors ? false : true;
      state.openVerify = false;
    },
    [uploadPartyLedgerStatement.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "MAP_PARTY_LEDGER_STATEMENT";
      state.error = action.payload;
    },
    //Map party Open Item Statement
    [uploadPartyOpenItemStatement.pending]: (state, action) => {
      state.status = "loading";
      state.type = "MAP_PARTY_OPENITEM_STATEMENT";
    },
    [uploadPartyOpenItemStatement.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "MAP_PARTY_OPENITEM_STATEMENT";
      state.mappingData = action.payload;
      state.openGood = action.payload?.hasErrors ? false : true;
      state.openVerify = false;
    },
    [uploadPartyOpenItemStatement.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "MAP_PARTY_OPENITEM_STATEMENT";
      state.error = action.payload;
    },
    //Remove Company Other Data Statement
    [removeCompanyOtherDataStatement.pending]: (state, action) => {
      state.status = "loading";
      state.type = "REMOVE_COMPANY_OTHER_DATA_STATEMENT";
    },
    [removeCompanyOtherDataStatement.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "REMOVE_COMPANY_OTHER_DATA_STATEMENT";
      state.removedData = action.payload;
    },
    [removeCompanyOtherDataStatement.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "REMOVE_COMPANY_OTHER_DATA_STATEMENT";
      state.error = action.payload;
    },
    // getSummaryDataNew for summary
    [getSummaryDataNew.pending]: (state, action) => {
      state.status = "loading";
      state.type = "TO_GET_NEW_SUMMARY";
    },
    [getSummaryDataNew.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "TO_GET_NEW_SUMMARY";
      state.removedData = action.payload;
    },
    [getSummaryDataNew.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "TO_GET_NEW_SUMMARY";
      state.error = action.payload;
    },
    //Stop Reconciliation
    [stopReconciliationProcess.pending]: (state, action) => {
      state.status = "loading";
      state.type = "STOP_RECON_PROCESS";
    },
    [stopReconciliationProcess.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "STOP_RECON_PROCESS";
    },
    [stopReconciliationProcess.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "STOP_RECON_PROCESS";
      state.error = action.payload;
    },
    // Download Company document
    [downloadComapnyDocument.pending]: (state, action) => {
      state.status = "loading";
      state.type = "DOWNLOAD_REPORT";
    },
    [downloadComapnyDocument.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "DOWNLOAD_REPORT";
    },
    [downloadComapnyDocument.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "DOWNLOAD_REPORT";
      state.error = action.payload;
    },
    // Download Party document
    [downloadPartyDocument.pending]: (state, action) => {
      state.status = "loading";
      state.type = "DOWNLOAD_PARTY_REPORT";
    },
    [downloadPartyDocument.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "DOWNLOAD_PARTY_REPORT";
    },
    [downloadPartyDocument.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "DOWNLOAD_PARTY_REPORT";
      state.error = action.payload;
    },
    // party name list
    [getPartyNames.pending]: (state, action) => {
      state.status = "loading";
      state.type = "GET_PARTY_NAMES";
    },
    [getPartyNames.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "GET_PARTY_NAMES";
      state.partyNameData = action.payload;
    },
    [getPartyNames.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "GET_PARTY_NAMES";
      state.error = action.payload;
    },
    // party name-Search list
    [getPartyNameSearch.pending]: (state, action) => {
      state.status = "loading";
      state.type = "GET_PARTY_NAME_SEARCHSTRING";
    },
    [getPartyNameSearch.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "GET_PARTY_NAME_SEARCHSTRING";
      state.partyNameData = action.payload;
    },
    [getPartyNameSearch.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "GET_PARTY_NAME_SEARCHSTRING";
      state.error = action.payload;
    },
    // get select Records Data
    [getRecordsData.pending]: (state, action) => {
      state.status = "loading";
      state.type = "GET_RECORDS";
    },
    [getRecordsData.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "GET_RECORDS";
      state.selectRecordsData = action.payload;
    },
    [getRecordsData.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "GET_RECORDS";
      state.error = action.payload;
    },
    // create new Reco
    [createNewReco.pending]: (state, action) => {
      state.status = "loading";
      state.type = "CREATE_NEW_RECO";
    },
    [createNewReco.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "CREATE_NEW_RECO";
      state.configureData = action.payload;
      state.partyId = action.payload.party.partyId;
      state.recoId = action.payload.recoId;
    },
    [createNewReco.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "CREATE_NEW_RECO";
      state.error = action.payload;
    },
    //get editable data
    [getEditableRecoData.pending]: (state, action) => {
      state.status = "loading";
      state.type = "GET_EDITABLE_RECO_DATA";
    },
    [getEditableRecoData.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "GET_EDITABLE_RECO_DATA";
      state.editableRecoData = action.payload;
    },
    [getEditableRecoData.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "GET_EDITABLE_RECO_DATA";
      state.error = action.payload;
    },
    //Save Reco Setting
    [saveRecoSettings.pending]: (state, action) => {
      state.status = "loading";
      state.type = "SAVE_RECO_SETTINGS";
    },
    [saveRecoSettings.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "SAVE_RECO_SETTINGS";
      // state.configureData = action.payload;
      // state.partyId = action.payload.party.partyId;
      // state.recoId = action.payload.recoId;

      if (action.payload.companyLedgerStatementName != null)
        state.companyLedgerArr = [
          {
            fileName: action.payload.companyLedgerStatementName,
            statementId: action.payload.companyLedgerStatementId,
            recoId: action.payload.recoId,
            partyId: action.payload.partyId,
          },
        ];
      if (action.payload.partyLedgerStatementName != null)
        state.partyLedgerArr = [
          {
            fileName: action.payload.partyLedgerStatementName,
            statementId: action.payload.partyLedgerStatementId,
            recoId: action.payload.recoId,
            partyId: action.payload.partyId,
          },
        ];
      if (action.payload.companyOpenItemStatementName != null)
        state.companyArr = [
          {
            fileName: action.payload.companyOpenItemStatementName,
            statementId: action.payload.companyOpenItemStatementId,
            recoId: action.payload.recoId,
            partyId: action.payload.partyId,
          },
        ];
      if (action.payload.partyOpenItemStatementName != null) {
        state.partyArr = [
          {
            fileName: action.payload.partyOpenItemStatementName,
            statementId: action.payload.partyOpenItemStatementId,
            recoId: action.payload.recoId,
            partyId: action.payload.partyId,
          },
        ];
      }
      if (action.payload.companyOtherData1Name !== null) {
        state.otherCompany = [
          {
            statementName: action.payload.companyOtherData1Name,
            statementId: action.payload.companyOtherData1Id,
            recoId: action.payload.recoId,
            partyId: action.payload.partyId,
          },
        ];
      }
      if (action.payload.companyOtherData2Name !== null)
        state.otherCompany = [
          ...state.otherCompany,
          {
            statementName: action.payload.companyOtherData2Name,
            statementId: action.payload.companyOtherData2Id,
            recoId: action.payload.recoId,
            partyId: action.payload.partyId,
          },
        ];
      if (action.payload.companyOtherData3Name !== null)
        state.otherCompany = [
          ...state.otherCompany,
          {
            statementName: action.payload.companyOtherData3Name,
            statementId: action.payload.companyOtherData3Id,
            recoId: action.payload.recoId,
            partyId: action.payload.partyId,
          },
        ];
    },
    [saveRecoSettings.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "SAVE_RECO_SETTINGS";
      state.error = action.payload;
    },
    //Save settings and rerun reco
    [saveSettingsAndRerunReco.pending]: (state, action) => {
      state.status = "loading";
      state.type = "SAVE_SETTINGS_AND_RERUN";
    },
    [saveSettingsAndRerunReco.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "SAVE_SETTINGS_AND_RERUN";
      if (action.payload.statementAvailable) {
        state.isLoading = true;
      }
      state.configureData = action.payload;
      // state.configureData = action.payload;
      state.partyId = action.payload.partyId;
      state.recoId = action.payload.recoId;

      if (action.payload.companyLedgerStatementName != null)
        state.companyLedgerArr = [
          {
            fileName: action.payload.companyLedgerStatementName,
            statementId: action.payload.companyLedgerStatementId,
            recoId: action.payload.recoId,
            partyId: action.payload.partyId,
          },
        ];
      if (action.payload.partyLedgerStatementName != null)
        state.partyLedgerArr = [
          {
            fileName: action.payload.partyLedgerStatementName,
            statementId: action.payload.partyLedgerStatementId,
            recoId: action.payload.recoId,
            partyId: action.payload.partyId,
          },
        ];
      if (action.payload.companyOpenItemStatementName != null)
        state.companyArr = [
          {
            fileName: action.payload.companyOpenItemStatementName,
            statementId: action.payload.companyOpenItemStatementId,
            recoId: action.payload.recoId,
            partyId: action.payload.partyId,
          },
        ];
      if (action.payload.partyOpenItemStatementName != null) {
        state.partyArr = [
          {
            fileName: action.payload.partyOpenItemStatementName,
            statementId: action.payload.partyOpenItemStatementId,
            recoId: action.payload.recoId,
            partyId: action.payload.partyId,
          },
        ];
      }
      if (action.payload.companyOtherData1Name !== null) {
        state.otherCompany = [
          {
            statementName: action.payload.companyOtherData1Name,
            statementId: action.payload.companyOtherData1Id,
            recoId: action.payload.recoId,
            partyId: action.payload.partyId,
          },
        ];
      }
      if (action.payload.companyOtherData2Name !== null)
        state.otherCompany = [
          ...state.otherCompany,
          {
            statementName: action.payload.companyOtherData2Name,
            statementId: action.payload.companyOtherData2Id,
            recoId: action.payload.recoId,
            partyId: action.payload.partyId,
          },
        ];
      if (action.payload.companyOtherData3Name !== null)
        state.otherCompany = [
          ...state.otherCompany,
          {
            statementName: action.payload.companyOtherData3Name,
            statementId: action.payload.companyOtherData3Id,
            recoId: action.payload.recoId,
            partyId: action.payload.partyId,
          },
        ];
    },
    [saveSettingsAndRerunReco.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "SAVE_SETTINGS_AND_RERUN";
      state.error = action.payload;
    },
    //get selected party settings-to be pre filled
    [getSelectedPartySettings.pending]: (state, action) => {
      state.status = "loading";
      state.type = "GET_SELECTED_PARTY_SETTINGS";
    },
    [getSelectedPartySettings.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "GET_SELECTED_PARTY_SETTINGS";
    },
    [getSelectedPartySettings.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "GET_SELECTED_PARTY_SETTINGS";
      state.error = action.payload;
    },
    //get all party party uploaded documents list
    [getAllPartyUploadedDocumentsList.pending]: (state, action) => {
      state.status = "loading";
      state.type = "GET_ALL_PARTY_UPLOADED_DOCUMENTS_LIST";
    },
    [getAllPartyUploadedDocumentsList.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "GET_ALL_PARTY_UPLOADED_DOCUMENTS_LIST";
    },
    [getAllPartyUploadedDocumentsList.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "GET_ALL_PARTY_UPLOADED_DOCUMENTS_LIST";
      state.error = action.payload;
    },
    //get all party party uploaded documents list non-excel
    [getAllPartyUploadedNonExcel.pending]: (state, action) => {
      state.status = "loading";
      state.type = "GET_ALL_PARTY_UPLOADED_NON_EXCEL";
    },
    [getAllPartyUploadedNonExcel.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "GET_ALL_PARTY_UPLOADED_NON_EXCEL";
      state.partyDocList = action.payload.nonExcelStatements;
    },
    [getAllPartyUploadedNonExcel.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "GET_ALL_PARTY_UPLOADED_NON_EXCEL";
      state.error = action.payload;
    },
    [uploadSFTPFileSP.pending]: (state, action) => {
      state.status = "loading";
      state.type = "UPLOAD_SFTP_FILE";
    },
    [uploadSFTPFileSP.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "UPLOAD_SFTP_FILE";
      state.mappingData = action.payload;
      state.openGood = action.payload?.hasErrors ? false : true;
      state.openVerify = false;
    },
    [uploadSFTPFileSP.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "UPLOAD_SFTP_FILE";
      state.error = action.payload;
    },
    [fetchTransformedFileDetails.pending]: (state, action) => {
      state.status = "loading";
      state.type = "GET_TRANSFORMED_FILE_DETAILS";
    },
    [fetchTransformedFileDetails.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "GET_TRANSFORMED_FILE_DETAILS";
    },
    [fetchTransformedFileDetails.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "GET_TRANSFORMED_FILE_DETAILS";
      state.error = action.payload;
    },
    [uploadSFTPGrirSP.pending]: (state, action) => {
      state.status = "loading";
      state.type = "MAP_COMPANY_OTHER_STATEMENT_SFTP";
    },
    [uploadSFTPGrirSP.fulfilled]: (state, action) => {
      state.status = "succeed";
      state.type = "MAP_COMPANY_OTHER_STATEMENT_SFTP";
      state.summary = action.payload;
      state.otherData = action.payload;
      state.openModal = true;
      state.openOtherMapping = true;
      state.openGood = action.payload?.hasErrors ? false : true;
    },
    [uploadSFTPGrirSP.rejected]: (state, action) => {
      state.status = "failed";
      state.type = "MAP_COMPANY_OTHER_STATEMENT_SFTP";
      state.error = action.payload;
      state.openVerify = false;
    },
  },
  reducers: {
    setOpenFormat(state, action) {
      state.openFormat = action.payload;
    },
    setOpenMapping(state, action) {
      state.openMapping = action.payload;
    },
    setOpenOtherMapping(state, action) {
      state.openOtherMapping = action.payload;
    },
    setOpenVerify(state, action) {
      state.openVerify = action.payload;
    },
    setOpenGood(state, action) {
      state.openGood = action.payload;
    },
    setOpenModal(state, action) {
      state.openModal = action.payload;
    },
    setStatement(state, action) {
      state.statement = action.payload;
    },
    setStatementType(state, action) {
      state.statementType = action.payload;
    },
    setSelectedFile(state, action) {
      state.selectedFile = action.payload;
    },
    setCompanyArr(state, action) {
      state.companyArr = action.payload;
    },
    setPartyArr(state, action) {
      state.partyArr = action.payload;
    },
    setCompanyLedgerArr(state, action) {
      state.companyLedgerArr = action.payload;
    },
    setPartyLedgerArr(state, action) {
      state.partyLedgerArr = action.payload;
    },
    setOtherCompany(state, action) {
      state.otherCompany = action.payload;
    },
    setOtherParty(state, action) {
      state.otherParty = action.payload;
    },
    setOtherId(state, action) {
      state.otherId = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setSort(state, action) {
      state.sort = action.payload;
    },
    setConfigureData(state, action) {
      state.configureData = action.payload;
    },
    setRecoStatus(state, action) {
      state.recoStatus = action.payload;
    },
    setRefresh(state, action) {
      state.refresh = action.payload;
    },
    setRecoId(state, action) {
      state.recoId = action.payload;
    },
    setViewPartyId(state, action) {
      state.partyId = action.payload;
    },
    setFromTrackReco(state, action) {
      state.fromTrackReco = action.payload;
    },
    setFromDirectReco(state, action) {
      state.fromDirectkReco = action.payload;
    },
    setMappingStatus(state, action) {
      state.mappingStatus = action.payload;
    },
    setRejectFromUpload(state, action) {
      state.rejectFromUpload = action.payload;
    },
    setIsLoadingFile(state, action) {
      state.isLoadingFile = action.payload;
    },
    setSelectedPartyNames(state, action) {
      state.selectedPartyName = action.payload;
    },
    setRecoIdforEdit(state, action) {
      state.recoIdforEdit = action.payload;
    },
    setPartyIdforEdit(state, action) {
      state.partyIdforEdit = action.payload;
    },
    setSelectedRecoPeriod(state, action) {
      state.selectedRecoPeriod = action.payload;
    },
    setSettingResponse(state, action) {
      state.settingResponse = action.payload;
    },
    setPartyNameFordisplay(state, action) {
      state.partyNameForDisplay = action.payload;
    },
    setPartyNameForEditDisplay(state, action) {
      state.partyNameForEditDisplay = action.payload;
    },
    setUploadModalTitle(state, action) {
      state.uploadModalTitle = action.payload;
    },
    clearAll(state, action) {
      state.openFormat = false;
      state.openMapping = false;
      state.openVerify = false;
      state.openGood = false;
      state.openModal = false;
      state.isLoading = false;
      state.statement = "";
      state.statementType = "";
      state.selectedFile = [];
      state.companyArr = [];
      state.partyArr = [];
      state.companyLedgerArr = [];
      state.partyLedgerArr = [];
      state.otherCompany = [];
      state.otherParty = [];
      state.otherId = null;
      state.recoData = null;
      state.refresh = false;
      state.fromTrackReco = false;
      state.mappingStatus = false;
      state.rejectFromUpload = false;
      state.isLoadingFile = false;
      state.uploadModalTitle = "";
    },
  },
});

export const {
  setOpenFormat,
  setOpenMapping,
  setOpenOtherMapping,
  setOpenVerify,
  setOpenGood,
  setOpenModal,
  setStatement,
  setStatementType,
  setSelectedFile,
  setCompanyArr,
  setPartyArr,
  setCompanyLedgerArr,
  setPartyLedgerArr,
  setOtherCompany,
  setOtherParty,
  setOtherId,
  clearAll,
  setIsLoading,
  setSort,
  setConfigureData,
  setRecoStatus,
  setRefresh,
  setRecoId,
  setViewPartyId,
  setFromTrackReco,
  setMappingStatus,
  setRejectFromUpload,
  setIsLoadingFile,
  setSelectedPartyNames,
  setRecoIdforEdit,
  setPartyIdforEdit,
  setSelectedRecoPeriod,
  setSettingResponse,
  setPartyNameFordisplay,
  setPartyNameForEditDisplay,
  setFromDirectReco,
  setUploadModalTitle,
} = directReconciliationSlice.actions;

const { reducer } = directReconciliationSlice;

export default reducer;
