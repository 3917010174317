const initialValues = {
  status: null,
  error: null,
  type: null,
  isLoading: false,
  activeValue: "1",
  singleParty: {},
  partyDetails: {
    persons: [],
    internalTeam: {
      owner: {},
      firstReviewers: [],
      secondReviewers: [],
      businessUsers: [],
    },
    reconciliationSetting: {
      category: "",
      frequency: "",
      gstRate: "",
      tdsRate: "",
    },
    otherDetails: {
      pan: "",
      gstin: "",
      msmeClass: "",
      msmeType: "",
      udhyamRegistrationNumber: "",
    },
    erps: [],
  },
  allParty: [],
  tableData: [],
  partyType: [],
  statusType: [],
  frequencyType: [],
  categoryType: [],
  msmeType: [],
  msmeClass: [],
  companyType: [],
  internalTeamType: [],
  errorFile: [],
  uploadParty: [],
  exportParty: [],
  template: [],
  sort: { id: "", direction: "ascending" },
  editAllparty: [],
  outhToken: "",
  panError: false,
  uploadedFileName: "",
  gstError: false,
  udhyamError: false,
  userData: {},
  isAdmin: false,
  isTokenExpire: false,
  id: "",
  openSnackBarImportValidFile: false,
  openSnackBarImportInValidFile: false,
  downloadReport: false,
  showDownloadBox: "hidden",
  reportCount: 0,
  reportList: [],
  errorSnackbar: false,
  allowedStatusesForRejectStatement: [],
  companyId: "",
  isNewParty: true,
  isChanged: false,
  errorMsg: "",
};

export default initialValues;
